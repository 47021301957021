import { useState } from "react";
import { Container, Row, Col, Dropdown, NavDropdown, Form, FormControl, Badge } from "react-bootstrap"
import { CloseButton, Button, Table, InputGroup, OverlayTrigger, Popover } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import { getProgettoColor, } from '../../../Utilities/getBorderColor'
import { getStatusOrderProgetto, } from '../../../Utilities/getStatusOrder'
import { FiChevronLeft } from 'react-icons/fi'
import { statiProgetto, } from "../../../Utilities/statiElementEnum";
import { BsQuestionCircle } from "react-icons/bs"
import { PiFileCsv } from "react-icons/pi";
import { convertProgettiToCSV,  } from "../../../Utilities/covertToCSV";
import allegatoProgettoServiceInstance from "../../../../services/AllegatoProgettoService";
import progettoServiceInstance from "../../../../services/ProgettoService";

import AzioniProgetti from "./AzioniProgetti";
 const dayjs = require('dayjs');


export default function ProgettiElenco(props) {

    let aree = (props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcAreeUrbane && props.comune.impostazioniComune.imcAreeUrbane.aree_urbane) || ["Caricamento..."]
    //let progetti = props.iniziative.filter(el => el.iniId === props.iniId)[0].progetti
    let categorie = [...new Set([...(props.comune.impostazioniComune?.imcCategorieProgetti?.categorie_progetti || []), ...props.progettiIniziativa.flatMap(p => p.progCategorie.categorie)])].sort()

    const [titolo, setTitolo] = useState("")
     let [statoProgettoFilter, setStatoProgettoFilter] = useState("")
     let [areaFilter, setAreaFilter] = useState("")
    let [categoriaFilter, setCategoriaFilter] = useState("")

    const getProgetti = () => {
        let ret = props.progettiIniziativa

        if (statoProgettoFilter.length > 0)
            ret = ret.filter((pro) => pro.progStato === statoProgettoFilter)

        if (areaFilter.length > 0)
            ret = ret.filter((pro) => pro.progArea === areaFilter)

        if (categoriaFilter.length > 0)
            ret = ret.filter((pro) => pro.progCategorie.categorie.includes(categoriaFilter))

        if (titolo.length > 0)
            ret = ret.filter((pro => pro.progTitolo.toLowerCase().includes(titolo.toLowerCase())))

        return ret
    }

    const redirectToList = () => {
        props.setMostraTabella(true)
        setStatoProgettoFilter("")
        setAreaFilter("")
    }

    let filterByStatoProgetto = (statoProgetto) => {
        setStatoProgettoFilter(statoProgetto)
    }

    let resetStatoProgetto = () => {
        setStatoProgettoFilter("")
    }

    let filterByArea = (area) => {
        setAreaFilter(area)
    }

    let resetArea = () => {
        setAreaFilter("")
    }

    let filterByCategoria = (categoria) => {
        setCategoriaFilter(categoria)
    }
    let resetCategoria = (categoria) => {
        setCategoriaFilter("")
    }


    

    const handleDownloadReports = async () => {
        try {
            const progettiIniziativa = props.progettiIniziativa
            console.log("progettiIniziativa", progettiIniziativa)
            // Ottieni tutti i progetti relativi all'iniziativa
    
            // Per ogni progetto, ottieni il conteggio degli allegati
            const progettiWithAllegatiCount = await Promise.all(
                progettiIniziativa.map(async (progetto) => {
                    const count = await allegatoProgettoServiceInstance.getCountAllegatiByProgetto(progetto.progId)
                        .then((response) => 
                            response.data

                        )
                        .catch(() => 0); // Imposta 0 in caso di errore
                    return { ...progetto, allegatiCount: count };
                })
            );
    
            // Genera il CSV
            const csvContent = convertProgettiToCSV(progettiWithAllegatiCount, props.iniziative.filter(el => el.iniId === props.iniId)[0].iniTitolo); // Converti i dati in CSV
            const url = window.URL.createObjectURL(new Blob([csvContent])); // Crea l'URL 
            const link = document.createElement('a');
            const fileName = `Progetti_${props.iniziative.filter(el => el.iniId === props.iniId)[0].iniTitolo}_${dayjs(new Date()).format("DDMMYYHHmm")}.csv`;
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Errore durante il download dei report:", error);
        }
    };

    return (
        <>
            <Container className="p-0 px-md-4 py-md-0">
                <div className="d-flex justify-content-between">
                    <h2 className="title2">
                        Progetti per: {props.iniziative.filter(el => el.iniId === props.iniId)[0].iniTitolo}

                    </h2>
                    <OverlayTrigger trigger="click" rootClose placement="left"
                        overlay={
                            <Popover id="popover-contained" >
                                <Popover.Header as="h3">Aiuto:</Popover.Header>
                                <Popover.Body>
                                    Ecco tutti i progetti inviati per questa iniziativa.
                                    <br />
                                    <br />
                                    Possibili <strong>stati</strong>:
                                    <ul>
                                        <li><strong>In validazione</strong>: progetto non visibile ai partecipanti</li>
                                        <li><strong>Rifiutato</strong>: progetto di cui è visualizzabile solo il titolo</li>
                                        <li><strong>Pubblicato</strong>: progetto visibile ai partecipanti</li>
                                    </ul>
                                    <br />
                                    Possibili <strong>azioni</strong>:
                                    <ul>
                                        <li><strong>Controlla</strong>: per rifiutare o pubblicare il progetto</li>
                                        <li><strong>Rispondi</strong>: per inserire la risposta del comune</li>
                                    </ul>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <div className="my-auto" style={{ cursor: "pointer" }} >
                            <BsQuestionCircle size="2em" className="my-auto" />
                        </div>
                    </OverlayTrigger>
                </div>
                <Row className="d-flex justify-content-between my-4">
                    <Col>
                        <Dropdown className="d-inline" autoClose="inside">
                            <Dropdown.Toggle id="dropdown-autoclose-outside">
                                <span className="px-5">Filtra</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <NavDropdown title="Stato" className="noChangeOnClick" drop="end" onSelect={filterByStatoProgetto}>
                                    {Object.values(statiProgetto)
                                        .map((stato, index) => (
                                            <NavDropdown.Item key={index} eventKey={stato}>{stato}</NavDropdown.Item>
                                        )
                                        )}
                                </NavDropdown>
                                <NavDropdown title="Area" className="noChangeOnClick" drop="end" onSelect={filterByArea}>
                                    {
                                        aree.map((area, index) => (
                                            <NavDropdown.Item key={index} eventKey={area}>{area}</NavDropdown.Item>
                                        ))
                                    }
                                </NavDropdown>
                                <NavDropdown title="Categoria" className="noChangeOnClick" drop="end" onSelect={filterByCategoria}>
                                    {
                                        categorie.map((categoria, index) => (
                                            <NavDropdown.Item key={index} eventKey={categoria}>{categoria}</NavDropdown.Item>
                                        ))
                                    }
                                </NavDropdown>
                            </Dropdown.Menu>
                        </Dropdown>
                        {
                            statoProgettoFilter.length > 0 &&
                            <Form.Label className="mx-4 mt-2 mt-md-1 d-block">
                                Stato: <strong> {statoProgettoFilter}</strong>
                                <Badge bg="outline-danger">
                                    <CloseButton onClick={resetStatoProgetto} />
                                </Badge>
                            </Form.Label>
                        }
                        {
                            areaFilter.length > 0 &&
                            <Form.Label className="mx-4 mt-2 mt-md-1 d-block">
                                Area: <strong> {areaFilter}</strong>
                                <Badge bg="outline-danger">
                                    <CloseButton onClick={resetArea} />
                                </Badge>
                            </Form.Label>
                        }
                         {
                            categoriaFilter.length > 0 &&
                            <Form.Label className="mx-4 mt-2 mt-md-1 d-block">
                                Categoria: <strong> {categoriaFilter}</strong>
                                <Badge bg="outline-danger">
                                    <CloseButton onClick={resetCategoria} />
                                </Badge>
                            </Form.Label>
                        }
                    </Col>
                    <Col xs={12} md={5} className="d-flex justify-content-end">
                        <InputGroup className="mt-2 mt-md-0" style={{maxHeight:"1rem"}}>
                            <InputGroup.Text id="basic-addon1">
                                <GoSearch />
                            </InputGroup.Text>
                            <FormControl placeholder="Cerca titolo..."
                                onChange={(ev) => { setTitolo(ev.target.value) }} />
                        </InputGroup>
                    </Col>
                    <Col className="text-end">  
                        <Button onClick={handleDownloadReports} variant="primary" >
                            <PiFileCsv className="me-1" />
                            Esporta tabella in formato csv
                        </Button>
                    </Col>

                </Row>

                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Titolo</th>
                            <th>Autore</th>
                            <th>Categorie</th>
                            <th className="text-center">Area</th>
                            <th className="text-center">Stato</th>
                            <th className="text-center nowrap">Pubblicata il</th>
                            <th className="text-center">Azioni</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getProgetti().length > 0 ? (
                                getProgetti()
                                    .sort((a, b) => { return getStatusOrderProgetto(a.progStato) - getStatusOrderProgetto(b.progStato) })
                                    .map(progetto => (
                                        <tr key={progetto.progId}>
                                            <td>{progetto.progTitolo}</td>
                                            <td>{progetto.partecipante.datiPartecipante.dparNome} {progetto.partecipante.datiPartecipante.dparCognome}</td>
                                            <td>{progetto.progCategorie.categorie.join(", ")}</td>
                                            <td className="text-center">{progetto.progArea}</td>
                                            <td className="text-center">
                                                <Badge pill bg={getProgettoColor(progetto)} className="pillText" style={{ minWidth: "90%" }}>
                                                    {progetto.progStato}
                                                </Badge>
                                            </td>
                                            <td className="text-center">
                                                {progetto.progDataPubblicazione ? dayjs(progetto.progDataPubblicazione).format('DD/MM/YYYY') : "---"}
                                            </td>
                                            <td>
                                                 <AzioniProgetti
                                                    stato={progetto.progStato}
                                                    setGestisci={props.setGestisci}
                                                    setGestisciProgetto={props.setGestisciProgetto}
                                                    progetto={progetto}
                                                    setProgetto={props.setProgetto}
                                                /> 
                                            </td>
                                        </tr>
                                    ))
                            ) : (
                                <tr>
                                    <td colSpan={8}>
                                        Nessuna proposta trovata
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

                <Row>
                    <Container className="d-flex justify-content-between p-0 m-0 mt-4 mb-5">
                        <Button variant="outline-dark" onClick={redirectToList}>
                            <FiChevronLeft />
                            Indietro
                        </Button>

                    </Container>
                </Row>
            </Container>
        </>
    )

}