import { Col, Container, Row } from "react-bootstrap";
import petizioni_icon from '../../../immagini/icone_sidebar/petizioni.png'
import referendum_icon from '../../../immagini/icone_sidebar/referendum.png'
import bp_icon from '../../../immagini/icone_sidebar/bilancio_partecipativo.png'
import {iniziative_white as iniziative_icon}  from '../../../utilities/icone'
import segnalazioni_icon from '../../../immagini/icone_sidebar/segnalazioni.png'
import { useEffect, useState } from "react";
import PetizioniService from "../../../services/PetizioniService";
import ReferendaService from "../../../services/ReferendaService";
import BilancioPartecipativoService from "../../../services/BilancioPartecipativoService";
import CartaElemento from "./CartaElemento";
import React from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';
import SegnalazioniService from "../../../services/SegnalazioniService";
import PaginaNonTrovata from "../../Utente/Error/PaginaNonTrovata";
import iniziativaServiceInstance from "../../../services/IniziativaService";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


export default function Cruscotto({ comune, partecipante }) {
    const [petizioni, setPetizioni] = useState([])
    const [referendum, setReferendum] = useState([])
    const [bilanci, setBilanci] = useState([])
    const [iniziative, setIniziative] = useState([])
    const [segnalazioni, setSegnalazioni] = useState([])

    // Stato per i dati del grafico
    const [chartData, setChartData] = useState(null);
    useEffect(() => {
        // Genera i dati solo una volta al primo montaggio
        const labels = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];

        const data = {
            labels,
            datasets: [
                {
                    label: '',
                    data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: '',
                    data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
                {
                    label: '',
                    data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                    borderColor: 'rgb(0,99,41)',
                    backgroundColor: 'rgba(0,99,41,0.5)',
                },
            ],
        };

        setChartData(data);
    }, []);

    useEffect(() => {
        if (comune.comId) {
            PetizioniService.findByComune(comune.comId)
                .then(res => {
                    setPetizioni(res.data)
                })
                .catch(() => {
                    setPetizioni([])
                })
            ReferendaService.findByComune(comune.comId)
                .then(res => {
                    setReferendum(res.data)
                })
                .catch(() => {
                    setReferendum([])
                })
            BilancioPartecipativoService.getAllByComune(comune.comId)
                .then(res => {
                    setBilanci(res.data)
                })
                .catch(() => {
                    setBilanci([])
                })
            SegnalazioniService.findByComune(comune.comId)
                .then(res => {
                    setSegnalazioni(res.data)
                })
                .catch(() => {
                    setSegnalazioni([])
                })
            /*iniziativaServiceInstance.getAllByComune(comune.comId)
            .then(res => {
                setIniziative(res.data)
            })
            .catch(() => {
                setIniziative([])
            })*/
                iniziativaServiceInstance.getIniziativaWithoutProjects(comune.comId)
                .then(res => {
                    setIniziative(res.data)
                })
                .catch(() => {
                    setIniziative([])
                })
        }
    }, [comune]);


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Andamento degli invii da parte degli utenti',
            },
        },
    };

    if (partecipante.parId && partecipante.ruolo.ruoFlAmministratore && comune.impostazioniComune) {
        return (
            <Container>
                <h1 className="title">Cruscotto</h1>

                <Row className="d-flex justify-content-between">
                    {comune.impostazioniComune.imcModuloPetizioniAttivo &&
                        <Col className="mt-2">
                            <CartaElemento
                                titolo="Petizioni"
                                parolaElementi="petizioni"
                                numero={petizioni.length}
                                icona={petizioni_icon}
                                parolaBottone="e le petizioni"
                                path="/admin/petizioni"
                            />
                        </Col>
                    }
                    {comune.impostazioniComune.imcModuloReferendumAttivo &&
                        <Col className="mt-2">
                            <CartaElemento
                                titolo="Referendum"
                                parolaElementi="referendum"
                                numero={referendum.length}
                                icona={referendum_icon}
                                parolaBottone="i i referendum"
                                path="/admin/referendum"
                            />
                        </Col>
                    }
                    {comune.impostazioniComune.imcModuloBilanciAttivo &&
                        <Col className="mt-2">
                            <CartaElemento
                                titolo={comune && comune.impostazioniComune && comune.impostazioniComune.imcLabelModuloBilanci}
                                parolaElementi={comune && comune.impostazioniComune && comune.impostazioniComune.imcLabelModuloBilanci}
                                numero={bilanci.length}
                                icona={bp_icon}
                                parolaBottone="e i bilanci"
                                path="/admin/bilanci-partecipativi"
                            />
                        </Col>
                    }
                    {comune.impostazioniComune.imcModuloSegnalazioniAttivo &&
                        <Col className="mt-2">
                            <CartaElemento
                                titolo="Segnalazioni"
                                parolaElementi="segnalazioni    "
                                numero={segnalazioni.length}
                                icona={segnalazioni_icon}
                                parolaBottone="e le segnalazioni"
                                path="/admin/segnalazioni"
                            />
                        </Col>
                    }

{comune.impostazioniComune.imcModuloIniziativeAttivo &&
                        <Col className="mt-2">
                            <CartaElemento
                                titolo={comune && comune.impostazioniComune && comune.impostazioniComune.imcLabelModuloIniziative}
                                parolaElementi={comune && comune.impostazioniComune && comune.impostazioniComune.imcLabelModuloIniziative}
                                numero={iniziative.length}
                                icona={iniziative_icon}
                                parolaBottone="e le iniziative"
                                path="/admin/iniziative"
                            />
                        </Col>
                    }
                </Row>

                <Row className="mt-5 d-none d-md-block">
                    {chartData && <Line options={options} data={chartData} height="70vh" />}
                </Row>

            </Container >
        )

    } else {
        return (
            <PaginaNonTrovata />
        )
    }
}