import { getStatusBilancio, getStatusReferendum, getStatusPetizione, getStatusIniziativa } from "./getStatus";

const convertPropostaToCSV = (proposte, titolo) => {
    const intestazione = `"Proposte presentate per: ${titolo}"`
    const headers = [
        `"Titolo"`,
        `"Autore"`,
        `"Associazione"`,
        `"Email di contatto"`,
        `"Data Invio"`,
        `"Descrizione"`,
        `"Vincitrice"`,
        `"Pubblicata il"`,
        `"Categorie"`,
        `"Area"`,
        `"Stato"`,
        `"Voti Online"`,
        `"Voti Offline"`,
        `"Voti Totali"`,
        `"Numero Allegati"`,
        `"Data risposta"`,
        `"Risposta"`,
    ];
    // Crea le righe del CSV
    const rows = proposte.map(row => {
        var values = [
            `"${row.proTitolo}"`,  // Racchiudi i testi tra virgolette
            `"${row.partecipante.datiPartecipante.dparNome} ${row.partecipante.datiPartecipante.dparCognome}"`,  // Nome e cognome
            `"${row.proAssociazione?row.proAssociazione:"--"}"`,
            `"${row.proEmail?row.proEmail:"--"}"`,
            `"${row.proDataInvio}"`,
            `"${row.proDescrizione}"`,
            `"${row.proFlAmmessa ? "SI" : "NO"}"`,
            `"${row.proDataPubblicazione ? row.proDataPubblicazione : "--"}"`,
            `"${row.proCategorie.categorie.join(', ')}"`,  // Unisci le categorie con virgola e racchiudi tra virgolette
            `"${row.proArea}"`,
            `"${row.proStato}"`,
            `"${row.votiProposta.length}"`,  // Numero di voti online
            `"${row.proVotiOffline}"`,
            `"${row.proVotiOffline + row.votiProposta.length}"`,
            `"${row.allegati.length}"`,
            `"${row.proDataRisposta?row.proDataRisposta:""}"`,
            `"${row.proRisposta?row.proRisposta:""}"`,
        ];
        return values.join(',');
    });

    const footer = `"Totale proposte:","${proposte.length}"`

    // Unisci le intestazioni e le righe con le righe separate da "\n"
    return [intestazione, "", headers.join(','), ...rows, "", footer].join('\n');
};

const convertBilanciToCSV = (bilanci) => {
    const intestazione = `"Estrazione bilanci"`
    const headers = [
        `"Titolo"`,
        `"Edizione"`,
        `"Stato"`,
        `"Descrizione"`,
        `"Data Inizio"`,
        `"Data Chiusura"`,
        `"Numero Proposte"`,
        `"Numero Fasi"`
    ];

    const maxFasi = Math.max(...bilanci.map(row => row.fasi.length));
    for (let i = 1; i <= maxFasi; i++) {
        headers.push(
            `"Fase ${i} Titolo"`,
            `"Fase ${i} Data Inizio"`, 
            `"Fase ${i} Data Fine"`,
            `"Fase ${i} Descrizione"`, 
            `"Fase ${i} Invio Proposte"`, 
            `"Fase ${i} Votazioni"`);
    }

    // Crea le righe del CSV
    const rows = bilanci.map(row => {
        var values = [
            `"${row.bilTitolo}"`,  // Racchiudi i testi tra virgolette
            `"${row.bilEdizione}"`,
            `"${getStatusBilancio(row)}"`,
            `"${row.bilDescrizione}"`,
            `"${row.bilDataInizio ? row.bilDataInizio : "--"}"`,
            `"${row.bilDataChiusura ? row.bilDataChiusura : "--"}"`,
            `"${row.proposte.length}"`,
            `"${row.fasi.length}"`,
            ];

            // Aggiungi le informazioni delle fasi
        row.fasi.forEach(fase => {
            values.push(
                `"${fase.fasTitolo}"`,
                `"${fase.fasDataInizio}"`,
                `"${fase.fasDataFine}"`,
                `"${fase.fasDescrizione}"`,
                `"${fase.fasFlInvioProposte ? 'Sì' : 'No'}"`,
                `"${fase.fasFlVotazioni ? 'Sì' : 'No'}"`
            );
        });

        // Se ci sono meno fasi del massimo, aggiungi colonne vuote
        const fasiMancanti = maxFasi - row.fasi.length;
        for (let i = 0; i < fasiMancanti; i++) {
            values.push(`""`, `""`, `""`, `""`, `""`, `""`);
        }
        return values.join(',');
    });

    const footer = `"Totale bilanci:","${bilanci.length}"`

    // Unisci le intestazioni e le righe con le righe separate da "\n"
    return [intestazione, "", headers.join(','), ...rows, "", footer].join('\n');
};

const convertSegnalazioniToCSV = (segnalazioni) => {
    const intestazione = `"Estrazione segnalazioni"`
    const headers = [
        `"Titolo"`,
        `"Autore"`,
        `"Email di contatto"`,
        `"Data Invio"`,
        `"Descrizione"`,
        `"Categoria"`,
        `"Stato"`,
        `"Posizione"`,
        `"Numero allegati"`,
        `"Data Chiusura"`,
        `"Risposta"`,
    ];

    // Crea le righe del CSV
    const rows = segnalazioni.map(row => {
        var values = [
            `"${row.segOggetto}"`,
            `"${row.partecipante.datiPartecipante.dparNome} ${row.partecipante.datiPartecipante.dparCognome}"`,  // Nome e cognome
            `"${row.segEmail?row.segEmail:"--"}"`,
            `"${row.segDataInvio}"`,
            `"${row.segDescrizione}"`,
            `"${row.segCategoria}"`,
            `"${row.segStato}"`,
            `"${row.posizione.posNome}"`,
            `"${row.allegatiSegnalazione.length}"`,
            `"${row.segDataChiusura?row.segDataChiusura:"--"}"`,
            `"${row.segRisposta?row.segRisposta:"--"}"`,
            ];

        return values.join(',');
    });

    const footer = `"Totale segnalazioni:","${segnalazioni.length}"`

    // Unisci le intestazioni e le righe con le righe separate da "\n"
    return [intestazione, "", headers.join(','), ...rows, "", footer].join('\n');
};

const convertReferendumToCSV = (referendum, comune) => {
    const intestazione = `"Estrazione referendum"`
    const headers = [
        `"Titolo"`,
        `"Autore"`,
        `"Associazione"`,
        `"Email di contatto"`,
        `"Data Invio"`,
        `"Tipo"`,
        `"Descrizione"`,
        `"Pubblicato il"`,
        `"Inizio raccolta firme"`,
        `"Fine raccolta firme"`,
        `"Stato"`,
        `"Voti Online"`,
        `"Voti Offline"`,
        `"Voti Totali"`,
        `"Data Esito"`,
        `"Esito Consultazione"`,
    ];

    // Crea le righe del CSV
    const rows = referendum.map(row => {
        var values = [
            `"${row.refTitolo}"`,
            `"${row.partecipante.datiPartecipante.dparNome} ${row.partecipante.datiPartecipante.dparCognome}"`,  // Nome e cognome
            `"${row.refAssociazione?row.refAssociazione:"--"}"`,
            `"${row.refEmail?row.refEmail:"--"}"`,
            `"${row.refDataInvio}"`,
            `"${row.refTipo}"`,
            `"${row.refDescrizione}"`,
            `"${row.refDataPubblicazione? row.refDataPubblicazione: "--"}"`,
            `"${row.refInizioRaccoltaFirme? row.refInizioRaccoltaFirme: "--"}"`,
            `"${row.refFineRaccoltaFirme? row.refFineRaccoltaFirme: "--"}"`,
            `"${getStatusReferendum(row, comune)}"`,
            `"${row.firmeReferendum.length}"`,
            `"${row.refFirmeOffline}"`,
            `"${row.refFirmeOffline+row.firmeReferendum.length}"`,
            `"${row.refDataEsito? row.refDataEsito: "--"}"`,
            `"${row.refEsitoConsultazione?row.refEsitoConsultazione:"--"}"`,
            ];

        return values.join(',');
    });

    const footer = `"Totale referendum:","${referendum.length}"`

    // Unisci le intestazioni e le righe con le righe separate da "\n"
    return [intestazione, "", headers.join(','), ...rows, "", footer].join('\n');
};

const convertPetizioniToCSV = (petizioni, comune) => {
    const intestazione = `"Estrazione referendum"`
    const headers = [
        `"Titolo"`,
        `"Anonima"`,
        `"Autore"`,
        `"Associazione"`,
        `"Email di contatto"`,
        `"Data Invio"`,
        `"Categoria"`,
        `"Descrizione"`,
        `"Pubblicato il"`,
        `"Inizio raccolta firme"`,
        `"Fine raccolta firme"`,
        `"Stato"`,
        `"Firme Online"`,
        `"Firme Offline"`,
        `"Firme Totali"`,
        `"Ammessa"`,
        `"Data Risposta"`,
        `"Risposta"`,
    ];

    // Crea le righe del CSV
    const rows = petizioni.map(row => {
        var values = [
            `"${row.petTitolo}"`,
            `"${row.petFlAnonima ? "SI" : "NO"}"`,
            `"${row.partecipante.datiPartecipante.dparNome} ${row.partecipante.datiPartecipante.dparCognome}"`,  // Nome e cognome
            `"${row.petAssociazione? row.petAssociazione : "--"}"`,
            `"${row.petEmail?row.petEmail:"--"}"`,
            `"${row.petDataInvio}"`,
            `"${row.petCategoria}"`,
            `"${row.petDescrizione}"`,
            `"${row.petDataPubblicazione? row.petDataPubblicazione: "--"}"`,
            `"${row.petInizioRaccoltaFirme? row.petInizioRaccoltaFirme: "--"}"`,
            `"${row.petFineRaccoltaFirme? row.petFineRaccoltaFirme: "--"}"`,
            `"${getStatusPetizione(row, comune)}"`,
            `"${row.firmePetizione.length}"`,
            `"${row.petFirmeOffline}"`,
            `"${row.petFirmeOffline+row.firmePetizione.length}"`,
            `"${row.petFlAmmessa ? "SI" : "NO"}"`,
            `"${row.petDataRisposta?row.petDataRisposta:"--"}"`,
            `"${row.petRisposta?row.petRisposta:"--"}"`,
            ];

        return values.join(',');
    });

    const footer = `"Totale petizioni:","${petizioni.length}"`

    // Unisci le intestazioni e le righe con le righe separate da "\n"
    return [intestazione, "", headers.join(','), ...rows, "", footer].join('\n');
};

const convertIniziativeToCSV = (iniziative) => {
    const intestazione = `"Estrazione iniziative"`
    const headers = [
        `"Titolo"`,
        `"Edizione"`,
        `"Stato"`,
        `"Descrizione"`,
        `"Data Inizio"`,
        `"Data Chiusura"`,
        `"Numero Progetti"`,
        `"Numero Fasi"`
    ];

    const maxFasi = Math.max(...iniziative.map(row => row.fasiIniziativa.length));
    for (let i = 1; i <= maxFasi; i++) {
        headers.push(
            `"Fase ${i} Titolo"`,
            `"Fase ${i} Data Inizio"`, 
            `"Fase ${i} Data Fine"`,
            `"Fase ${i} Descrizione"`, 
            `"Fase ${i} Invio Proposte"`
        ) 
    }

    // Crea le righe del CSV
    const rows = iniziative.map(row => {
        var values = [
            `"${row.iniTitolo}"`,  // Racchiudi i testi tra virgolette
            `"${row.iniEdizione}"`,
            `"${getStatusIniziativa(row)}"`,
            `"${row.iniDescrizione}"`,
            `"${row.iniDataInizio ? row.iniDataInizio : "--"}"`,
            `"${row.iniDataChiusura ? row.iniDataChiusura : "--"}"`,
           // `"${row.progetti.length}"`,
            `"${row.progettiCount}"`, // Usa il campo progettiCount // sttraverso una Count(select)
            `"${row.fasiIniziativa.length}"`,
            ];

            // Aggiungi le informazioni delle fasi
        row.fasiIniziativa.forEach(fase => {
            values.push(
                `"${fase.finiTitolo}"`,
                `"${fase.finiDataInizio}"`,
                `"${fase.finiDataFine}"`,
                `"${fase.finiDescrizione}"`,
                `"${fase.finiFlInvioProposte ? 'Sì' : 'No'}"`,
            );
        });

        // Se ci sono meno fasi del massimo, aggiungi colonne vuote
        const fasiMancanti = maxFasi - row.fasiIniziativa.length;
        for (let i = 0; i < fasiMancanti; i++) {
            values.push(`""`, `""`, `""`, `""`, `""`);
        }
        return values.join(',');
    });

    const footer = `"Totale iniziative:","${iniziative.length}"`

    // Unisci le intestazioni e le righe con le righe separate da "\n"
    return [intestazione, "", headers.join(','), ...rows, "", footer].join('\n');
};

const convertProgettiToCSV = (progetti, titolo) => {
    const intestazione = `"Progetti presentati per: ${titolo}"`
    const headers = [
        `"Titolo"`,
        `"Autore"`,
        `"Associazione"`,
        `"Copresentatori"`,
        `"Email di contatto"`,
        `"Data Invio"`,
        `"Quali sono i problemi e i bisogni che la proposta vuole affrontare e che cosa vuole migliorare?"`,
        `"Quali sono i luoghi, gli spazi e i servizi sui quali si concentra la proposta?"`,
        `"A chi si rivolge la proposta?"`,
        `"Quali sono i punti di forza della proposta?"`,
        `"Pubblicato il"`,
        `"Categorie"`,
        `"Area"`,
        `"Indirizzo"`,
        `"Latitudine"`,
        `"Longitudine"`,
        `"Stato"`,
        `"Numero Allegati"`,
        `"Data risposta"`,
        `"Risposta"`,
    ];
    // Crea le righe del CSV
    const rows = progetti.map(row => {
        var values = [
            `"${row.progTitolo}"`,  // Racchiudi i testi tra virgolette
            `"${row.partecipante.datiPartecipante.dparNome} ${row.partecipante.datiPartecipante.dparCognome}"`,  // Nome e cognome
            `"${row.progAssociazione?row.progAssociazione:"--"}"`,
            `"${row.progCopresentatori?row.progCopresentatori:"--"}"`,
            `"${row.progEmail?row.progEmail:"--"}"`,
            `"${row.progDataInvio}"`,
            `"${row.progDescrizione1}"`,
            `"${row.progDescrizione2}"`,
            `"${row.progDescrizione3}"`,
            `"${row.progDescrizione4}"`,
            `"${row.progDataPubblicazione ? row.progDataPubblicazione : "--"}"`,
            `"${row.progCategorie.categorie.join(', ')}"`,  // Unisci le categorie con virgola e racchiudi tra virgolette
            `"${row.progArea}"`,
            `"${row.posizione?.posNome?row.posizione.posNome:"---" }"`,
            `"${row.posizione?.posLatitudine?row.posizione.posLatitudine:"---" }"`,
            `"${row.posizione?.posLongitudine?row.posizione.posLongitudine:"---" }"`,
            `"${row.progStato}"`,
            //`"${row.allegatiProgetto.length}"`,
            `"${row.allegatiCount}"`,
            `"${row.progDataRisposta?row.progDataRisposta:""}"`,
            `"${row.progRisposta?row.progRisposta:""}"`,
        ];
        return values.join(',');
    });

    const footer = `"Totale progetti:","${progetti.length}"`

    // Unisci le intestazioni e le righe con le righe separate da "\n"
    return [intestazione, "", headers.join(','), ...rows, "", footer].join('\n');
};


export {
    convertPropostaToCSV,
    convertBilanciToCSV,
    convertSegnalazioniToCSV,
    convertReferendumToCSV,
    convertPetizioniToCSV,
    convertIniziativeToCSV,
    convertProgettiToCSV
}