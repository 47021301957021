import React from "react"
import { useState, useEffect } from "react"
import { Container, Tab, Tabs, OverlayTrigger, Popover, Spinner } from "react-bootstrap"
import ModuloNonAttivo from "../ModuloNonAttivo";
import { useNavigate, useLocation } from "react-router-dom";
import PaginaNonTrovata from "../../Utente/Error/PaginaNonTrovata"
import { BsQuestionCircle } from "react-icons/bs"
import iniziativaServiceInstance from "../../../services/IniziativaService"
import allegatoProgettoServiceInstance from "../../../services/AllegatoProgettoService"
import TabellaIniziative from "./TabellaIniziative";
import ImpostazioniIniziative from "./ImpostazioniIniziative";
import GestisciIniziativa from "./GestisciIniziativa";
import ProgettiElenco from "./Progetti/ProgettiElenco";
import ProgettiGestisci from "./Progetti/ProgettiGestisci";
import { RiSafariFill } from "react-icons/ri";


export default function Iniziative(props) {
    const navigate = useNavigate();
    const location = useLocation();

    // Dirty bit
    let [dirty, setDirty] = useState(true)

    // Stoppa il ricaricamento dei dati quando vengono caricati la prima volta. Viene riattivato quando c'è bisogno di caricare nuovamente i dati del db
    // (dopo) un inserimento di righe
    let [stopLoading, setStopLoading] = useState(false)

    let [isLoading, setIsLoading] = useState(true)

    // Fetch bilanci
    let [iniziative, setIniziative] = useState([])

    // Stato che decide come deve essere gestito il bilancio
    let [gestisci, setGestisci] = useState("")

    // Stato che decide come deve essere gestita la proposta
    let [gestisciProgetto, setGestisciProgetto] = useState("")

    // Stato per l'applicazione del filtro stato sui bilanci
    let [statoFilter, setStatoFilter] = useState("")

    // Stato che passa l'id del bilancio
    let [iniId, setIniId] = useState("")

    let [progetto, setProgetto] = useState()
    let [progettiIniziativa, setProgettiIniziativa] = useState([])


    const [isLoadingProgetti, setIsLoadingProgetti] = useState(true);

    const [allegatiProgetto, setAllegatiProgetto] = useState([]);
    const [isLoadingAllegati, setIsLoadingAllegati] = useState(false);

    /*useEffect(() => {
        if (dirty && props.comune) {
            setIsLoading(true); // Imposta isLoading a true prima del caricamento
            console.log("iniziative dirty: ", dirty)
            iniziativaServiceInstance.getAllByComune(props.comune.comId)
                .then(res => {
                    setIniziative(res.data);
                    console.log("setIniziative da db", res.data)
                })
                .then(() => {
                    setStopLoading(false)
                })
                .catch((err) => {
                    setIniziative([])
                })
                .finally(() => {
                    setIsLoading(false); // Dati caricati o errore, fine caricamento
                    if (props.comune.comId)
                        setDirty(false)
                })
        }
    }, [props.comune, dirty]);*/

    useEffect(() => {
        if (dirty && props.comune) {
            setIsLoading(true); // Imposta isLoading a true prima del caricamento
            console.log("iniziative dirty: ", dirty)
            iniziativaServiceInstance.getIniziativaWithoutProjects(props.comune.comId)
                .then(res => {
                    setIniziative(res.data);
                    console.log("setIniziative da db", res.data)
                })
                .then(() => {
                    setStopLoading(false)
                })
                .catch((err) => {
                    setIniziative([])
                })
                .finally(() => {
                    setIsLoading(false); // Dati caricati o errore, fine caricamento
                    if (props.comune.comId)
                        setDirty(false)
                })
        }
    }, [props.comune, dirty]);

    const loadAllegatiProgetto = (id) =>{
        // Carica gli allegati del progetto
        setIsLoadingAllegati(true);
        allegatoProgettoServiceInstance.getAllegatiByProgId(id)
            .then(res=>{
                console.log("allegatiProgetto", res.data)
                setAllegatiProgetto(res.data || []);
            })
            .catch((err) => {
                setAllegatiProgetto([])
            })
            .finally(() => {
                setIsLoadingAllegati(false); // Dati caricati o errore, fine caricamento
            })
    }

    const loadProgetti = (id) => {
        setIsLoadingProgetti(true);
        iniziativaServiceInstance
            .getIniziativa(id)
            .then((res) => {
                console.log(res.data)
                setProgettiIniziativa(res.data.progetti || []); 
            })
            .catch(() => setProgettiIniziativa([]))
            .finally(() => setIsLoadingProgetti(false));
    };

    useEffect(() => {
        if (gestisci === "progetti-elenco" && iniId) {
            loadProgetti(iniId);
        }else if(gestisci === "progetto-gestisci" && progetto.progId){
            loadAllegatiProgetto(progetto.progId)
        }else{
            setIsLoadingProgetti(true)
            setIsLoadingAllegati(true)
        }
    }, [gestisci, iniId]); // Si attiva solo quando `gestisci` o `iniId` cambiano

    const toggleTab = (ev) => {
        if (ev.target.dataset.rrUiEventKey === "impostazioni") {
            props.setTabAttivaIniziative("impostazioni")
            navigate("/admin/iniziative#impostazioni")
        }
        if (ev.target.dataset.rrUiEventKey === "iniziative") {
            props.setTabAttivaIniziative("iniziative")
            navigate("/admin/iniziative#tutte-le-iniziative")
        }
    }
    useEffect(() => {
        if (location.hash === "#tutte-le-iniziative") props.setTabAttivaIniziative("iniziative")
        if (location.hash === "#impostazioni") props.setTabAttivaIniziative("impostazioni")
    }, [props, location])


    if (props.partecipante.parId && props.partecipante.ruolo.ruoFlAmministratore) {

        return (
            <Container fluid="lg">
                <div className="d-flex justify-content-between">
                    <h1 className="title">{props.comune.impostazioniComune.imcLabelModuloIniziative}</h1>
                    {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloIniziativeAttivo &&
                        <OverlayTrigger trigger="click" rootClose placement="left"
                            overlay={
                                <Popover id="popover-contained" >
                                    <Popover.Header as="h3">Aiuto:</Popover.Header>
                                    <Popover.Body>
                                        Ecco tutte le {props.comune.impostazioniComune.imcLabelModuloIniziative} create.
                                        <br />
                                        <br />
                                        Possibili <strong>stati</strong>:
                                        <ul>
                                            <li><strong>In corso</strong>: iniziativa visibile ai partecipanti</li>
                                            <li><strong>Non pubblicata</strong>: iniziativa non ancora completa e non visibile ai partecipanti</li>
                                            <li><strong>Conclusa</strong>: iniziativa che ha raggiunto la sua data di chiusura</li>
                                        </ul>
                                        <br />
                                        Possibili <strong>azioni</strong>:
                                        <ul>
                                            <li><strong>Nuovo +</strong>: per creare una nuova iniziativa</li>
                                            <li><strong>Controlla</strong>: per modificare i dati o le caratteristiche dell'iniziativa</li>
                                            <li><strong>Progetti</strong>: per gestire i progetti inviati dai partecipanti per quell'iniziativa</li>
                                        </ul>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <div className="my-auto" style={{ cursor: "pointer" }} >
                                <BsQuestionCircle size="2em" className="my-auto" />
                            </div>
                        </OverlayTrigger>
                    }
                </div>
                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloIniziativeAttivo ? (
                    <Tabs activeKey={props.tabAttivaIniziative} id="uncontrolled-tab-example" className="mb-3"
                        onClick={toggleTab}>
                        <Tab eventKey="iniziative" title={`Tutte le ${props.comune.impostazioniComune.imcLabelModuloIniziative}`}>
                            {
                                props.mostraTabella ? (
                                                <TabellaIniziative
                                                    iniziative={iniziative}
                                                    setIniId={setIniId}
                                                    setMostraTabella={props.setMostraTabella}
                                                    setGestisci={setGestisci}
                                                    statoFilter={statoFilter}
                                                    setStatoFilter={setStatoFilter}
                                                    setDirty={setDirty}
                                                    dirty={dirty}
                                                    isLoading={isLoading}
                                                />
                                ) : (
                                    gestisci !== "progetti-elenco" && gestisci !== "progetto-gestisci" ? (
                                        <GestisciIniziativa
                                            iniziative={iniziative}
                                            comune={props.comune}
                                            iniId={iniId}
                                            setMostraTabella={props.setMostraTabella}
                                            gestisci={gestisci}
                                            setGestisci={setGestisci}
                                            setDirty={setDirty}
                                            dirty={dirty}
                                        />
                                        ) : (
                                            gestisci === "progetti-elenco" ? (
                                                <>{
                                                    isLoadingProgetti ? (
                                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Caricamento...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (
                                                        <ProgettiElenco
                                                    comune={props.comune}
                                                    iniziative={iniziative}
                                                    iniId={iniId}
                                                    setMostraTabella={props.setMostraTabella}
                                                    setGestisci={setGestisci}
                                                    setGestisciProgetto={setGestisciProgetto}
                                                    setProgetto={setProgetto}
                                                    progettiIniziativa={progettiIniziativa}
                                                    allegatiProgetto={allegatiProgetto}
                                                />
                    )
                }</>
                                        ) : (
                                            gestisci === "progetto-gestisci" && (
                                                <>{
                                                    isLoadingAllegati ? (
                                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Caricamento...</span>
                                                            </Spinner>
                                                        </div>
                                                    ) : (
                                                <ProgettiGestisci
                                                    comune={props.comune}
                                                    iniziative={iniziative}
                                                    iniId={iniId}
                                                    setGestisci={setGestisci}
                                                    gestisciProgetto={gestisciProgetto}
                                                    progetto={progetto}
                                                    setProgetto={setProgetto}
                                                    setDirty={setDirty}
                                                    allegatiProgetto={allegatiProgetto}

                                                />)}</>
                                            )
                                        )
                                    )
                                )
                            }

                        </Tab>
                        <Tab eventKey="impostazioni" title="Impostazioni">
                            <ImpostazioniIniziative
                                iniziative={iniziative}
                                stopLoading={stopLoading}
                                setStopLoading={setStopLoading}
                                setDirty={setDirty}
                                comune={props.comune}
                                setRicaricaComune={props.setRicaricaComune}
                                setMostraTabella={props.setMostraTabella}
                            />
                        </Tab>
                    </Tabs>
                ) : (
                    <ModuloNonAttivo moduleName={props.comune.impostazioniComune.imcLabelModuloIniziative} />
                )}
            </Container>
        )

    } else {
        return (<PaginaNonTrovata />)
    }
}