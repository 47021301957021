import axios from 'axios';

const ALLEGATO_REST_API_URL = process.env.REACT_APP_API_PREFIX + 'allegato-progetto';

const headers = {
    'Content-Type': 'multipart/form-data'
}

class AllegatoProgettoService {
    salvaAllegati(allegati) {
        return axios.post(ALLEGATO_REST_API_URL + "/save", allegati, { headers });
    }

    /*eliminaAllegati(allegati) {
        return axios.post(ALLEGATO_REST_API_URL + "/delete", allegati, { headers });
    }*/
        
    eliminaAllegatiById(allegatiIds) {
        return axios.post(ALLEGATO_REST_API_URL + "/delete-by-id", allegatiIds, { "Content-Type": "application/json" });
    }

    getAllegatiByProgId(progId) {
        return axios.get(ALLEGATO_REST_API_URL + "/find-by-prog-id/" + progId);
    }

    getCountAllegatiByProgetto(progId) {
        return axios.get(`${ALLEGATO_REST_API_URL}/count-allegati/${progId}`, { headers });
    }


}

const allegatoProgettoServiceInstance = new AllegatoProgettoService();
export default allegatoProgettoServiceInstance;
