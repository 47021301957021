import React from "react"
import { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Card, Figure } from "react-bootstrap"
import { FiUpload } from 'react-icons/fi'
import { BsFileEarmarkRichtext } from "react-icons/bs"
import { GoTrash } from "react-icons/go"
import { IoMdAdd } from "react-icons/io"
import ModalConferma from "../../Utilities/ModalConferma";
import { statiIniziativa } from "../../Utilities/statiElementEnum";
import { max_dimensione_files } from "../../Utilities/variables";
import iniziativaServiceInstance from "../../../services/IniziativaService";
import AzioniIniziativa from "./AzioniIniziativa";
import Fase from "./IniziativaFase"
import iniziativaFasiServiceInstance from "../../../services/IniziativaFasiService";

const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export default function GestisciIniziativa(props) {

    // Estrazione del iniziativa da visualizzare nella pagina dettaglio
    let iniziativa = props.iniziative.filter(el => el.iniId === props.iniId)[0]
    
    // Valore che permette l'inizializzazione una volta sola al caricamento della pagina
    let [init, setInit] = useState(true)

    // Stati per gestire i campi modificabili del bilancio
    let [iniId, setIniId] = useState("")
    let [iniStato, setIniStato] = useState("")
    let [iniEdizione, setIniEdizione] = useState("")

    // EDIZIONE
    const getIniEditionForm = () => {
        let years = iniziativa?.iniEdizione.replace("Edizione ", "").split("/")
        if(!years) return
        if (years.length === 2)
            return years.join(" - ")
        else
            return years[0]
    }

    const getNewIniEdizione = () => {
        if (
            form.iniDataInizio &&
            form.iniDataChiusura
        ) {
            if (
                dayjs(form.iniDataInizio, 'YYYY-MM-DD', true).isValid() === true &&
                dayjs(form.iniDataChiusura, 'YYYY-MM-DD', true).isValid() === true
            ) {
                if (dayjs(form.iniDataChiusura).year() > dayjs(form.iniDataInizio).year())
                    return [dayjs(form.iniDataInizio).year(), dayjs(form.iniDataChiusura).year()].join(" - ")
                else if (dayjs(form.iniDataInizio).year() === dayjs(form.iniDataChiusura).year())
                    return dayjs(form.iniDataInizio).year()
            }
        } else if (form.iniDataInizio) {
            if (dayjs(form.iniDataInizio, 'YYYY-MM-DD', true).isValid() === true)
                return dayjs(form.iniDataInizio).year()
        } else if (form.iniDataChiusura) {
            if (dayjs(form.iniDataChiusura, 'YYYY-MM-DD', true).isValid() === true)
                return dayjs(form.iniDataChiusura).year()
        }

        return ""
    }

    const getIniEditionString = () => {
        if (iniEdizione.toString().includes(" - "))
            return "Edizione " + iniEdizione.split(" - ").join("/")
        else return "Edizione " + iniEdizione
    }

    // DATE
    /*
     * La data inizio viene duplicata nella variabile dataInizioCurrent per evitare che in fase
     * di modifica di un bilancio esistente si possa inserire una data di inizio
     * inferiore a quella memorizzata in db
    */
    let dataInizioCurrent = (props.gestisci !== "addNew") ? iniziativa?.iniDataInizio : dayjs()
    /*
     * La data inizio di ogni fase viene duplicata nell'array dateInizioFasiCurrent per evitare che in fase
     * di modifica di un bilancio esistente (e di una fase esistente) si possa inserire una data di inizio di una fase 
     * inferiore a quella memorizzata in db
    */
    let dateInizioFasiCurrent = props.gestisci !== "addNew" ? iniziativa?.fasiIniziativa.map(fase => {
        return fase.finiDataInizio
    }) : []
    let permettiSovrapposizioneFasi = true
    let permettiDateInizioFasiPrecedentiDateInizioDb = true
    let permettiDataInizioIniziativaPrecedenteDataInizioDb = true
    let permettiDataInizioPrimaDiOggi = false

    // BANNER
    let inputFile = useRef(null)
    const [file, setFile] = useState();
    const [uploadedFilesMetadata, setUploadedFilesMetadata] = useState([])
    const acceptedExtensions = ["image/*", "image/png", "image/jpg", "image/jpeg"]
    const [erroreAllegati, setErroreAllegati] = useState("")
    const handleDisplayFileDetails = async () => {
        if (inputFile?.files && inputFile.files[0]
            && inputFile.files[0].size <= max_dimensione_files
            && acceptedExtensions.includes(inputFile.files[0].type) //controlla estensione
        ) {
            setUploadedFilesMetadata(inputFile.files[0])
            setFile(URL.createObjectURL(inputFile.files[0]));
            setErroreAllegati("")
        } else {
            if (uploadedFilesMetadata.filter(file => file.name === inputFile.files[0].name).length > 1) setErroreAllegati("Non puoi caricare file con lo stesso nome.")
            else if (!acceptedExtensions.includes(inputFile.files[0].type)) setErroreAllegati("Il formato del file scelto non è accettabile.")
            else if (!inputFile.files[0].size <= max_dimensione_files) setErroreAllegati("Il file scelto è troppo grande. Riprova.")
        }
    }
    const handleUpload = (event) => {
        event.preventDefault()
        inputFile?.click();
    };
    const deleteFile = () => {
        setUploadedFilesMetadata([])
        setFile()
    }

    // FASI
    let [fasiToDelete, setFasiToDelete] = useState([])
    const aggiungiFase = () => {
        let dataInizio
        let dataFine

        if (form.iniFasi.length === 0) {
            dataInizio = dayjs().format('YYYY-MM-DD')
            dataFine = dayjs().add(2, 'M').format('YYYY-MM-DD')
        } else {
            dataInizio = dayjs(form.iniFasi[(form.iniFasi.length - 1)].finiDataFine).add(1, 'd').format('YYYY-MM-DD')
            dataFine = dayjs(dataInizio).add(2, 'M').format('YYYY-MM-DD')
        }
        setForm({
            ...form,
            iniFasi: [...form.iniFasi, {
                finiTitolo: "",
                finiDescrizione: "",
                finiDataInizio: dataInizio,
                finiDataFine: dataFine,
                finiFlInvioProposte: false,
                finiOrdine: (form.iniFasi.length + 1)
            }]
        })
    }
    const handleDeleteFase = (fase_d) => {
        setMostraModaleDeleteFase(true)
        setFaseToDelete(fase_d)
    }
    const deleteFase = (fase_d) => {
        setMostraModaleDeleteFase(false)

        if (props.gestisci === 'controlla')
            setFasiToDelete([...fasiToDelete, fase_d])
    
        setForm({
            ...form,
            iniFasi: form.iniFasi
                .filter(fase => fase.finiOrdine !== fase_d.finiOrdine)
                .map(fase => {
                    if (fase.finiOrdine > fase_d.finiOrdine)
                        return {...fase, finiOrdine: (fase.finiOrdine - 1)}
                    return fase
                })
        })
    }

    // VALIDAZIONE FORM
    const [form, setForm] = useState({
        iniTitolo: "",
        iniDataInizio: dayjs().format('YYYY-MM-DD'),
        iniDataChiusura: dayjs().add(2, 'M').format('YYYY-MM-DD'),
        iniDescrizione: "",
        iniFasi: []
    })
    const [validato, setValidato] = useState(false)
    const [errors, setErrors] = useState({})

    const mandatoryField = () => {
        if (["addNew", "controlla"].includes(props.gestisci))
            return <span>*</span>

        return ""
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })

        if (["iniDataInizio", "iniDataChiusura"].includes(field)) {
            let newIniEdizione = getNewIniEdizione()
            if (newIniEdizione !== "")
                setIniEdizione(newIniEdizione)
        }

        // Controllo se ci sono errori, e li rimuovo dall'oggetto Errors
        if (!!errors[field])
            setErrors({
                ...errors,
                [field]: null
            })
    }
    const setFaseField = (field, faseUpdated, faseIndex) => {
        let newIniFasi = form.iniFasi.map((fase, index) => {
            if (index === faseIndex)
                return faseUpdated
            return fase
        })

        if (
            errors.errorsFasi !== undefined &&
            errors.errorsFasi[faseIndex][field] !== undefined
        )
            delete errors.errorsFasi[faseIndex][field]

        setForm({
            ...form,
            iniFasi: newIniFasi
        })
    }
    const getValidationStyle = (field) => {
        return (validato ? (errors[field] ? "is-invalid" : "is-valid") : "")
    }
    const isErrors = (newErrors) => {
        let ret = false

        if (Object.keys(newErrors).length > 1)
            ret = true
        else {
            newErrors.errorsFasi.forEach(fase => {
                if (Object.keys(fase).length > 0)
                    ret = true
            })
        }

        return ret
    }

    // FINESTRE MODALI
    const [mostraModaleOperazione, setMostraModaleOperazione] = useState(false)
    const [mostraModaleSuccesso, setMostraModaleSuccesso] = useState(false)
    const [mostraModaleErrore, setMostraModaleErrore] = useState(false)
    const [parolaChiave, setParolaChiave] = useState("")
    const [titolo, setTitolo] = useState("")
    const [messaggio, setMessaggio] = useState("")
    const [mostraModaleDeleteFase, setMostraModaleDeleteFase] = useState(false)
    const [faseToDelete, setFaseToDelete] = useState(null)

    // AZIONI
    const handleSubmit = (saveType, e) => {
        e.preventDefault()

        setValidato(true);

        // Ottieni i nuovi errori
        let newErrors = checkIniziativa()

        if (isErrors(newErrors)) {
            // Presenza di errori: inserimento nell'oggetto Errors e submit bloccato
            setErrors(newErrors)
        } else {
            // Assenza di errori! Submit del form abilitato
            if (saveType === "salva-bozza")
                salvaIniziativa(saveType)
            else {
                setMostraModaleOperazione(true)
                if (saveType === "pubblica") {
                    setParolaChiave("Pubblica")
                    setTitolo("Pubblicazione dell'elemento")
                    setMessaggio("Sei sicuro di voler pubblicare l'elemento? L'operazione è irreversibile!")
                }
                if (saveType === "salva") {
                    setParolaChiave("Salva")
                    setTitolo("Salva l'elemento")
                    setMessaggio("Sei sicuro di voler modificare l'elemento? L'operazione è irreversibile!")
                }
            }
        }
    }

    const salvaIniziativa= (saveType) => {
        let iniziativa = {
            iniEdizione: getIniEditionString(),
            iniTitolo: form.iniTitolo,
            iniDataInizio: form.iniDataInizio,
            iniDataChiusura: form.iniDataChiusura,
            iniDescrizione: form.iniDescrizione,
            comune: props.comune,
            fasiIniziativa: form.iniFasi
        }

        saveType = saveType.toLowerCase()

        if (saveType === "salva-bozza")
            iniziativa.iniStato = statiIniziativa.NonPubblicata
        else if (["pubblica", "salva"].includes(saveType))
            iniziativa.iniStato = statiIniziativa.InCorso

        if (props.gestisci === "controlla")
            iniziativa.iniId = iniId

        var formData = new FormData()
        formData.append("iniziativa", JSON.stringify(iniziativa))
        if (uploadedFilesMetadata instanceof File) {
            // Nuovo file caricato
            formData.append("file", uploadedFilesMetadata);
            formData.append("fileToDelete", "false"); // Indica che il file non deve essere cancellato
        } else if (uploadedFilesMetadata === null || uploadedFilesMetadata.length === 0) {
            // File eliminato
            formData.append("fileToDelete", "true");
        }
    
        iniziativaServiceInstance.saveIniziativa(formData)
            .then(() => {
                if (fasiToDelete.length > 0)
                    iniziativaFasiServiceInstance.eliminaFasi(fasiToDelete)
            })
            .then(() => {
                if (saveType === "salva-bozza") {
                    setTitolo("Salva bozza")
                    setMessaggio("Bozza salvata con successo")
                } else if (saveType === "pubblica") {
                    setTitolo("Pubblica elemento")
                    setMessaggio("Elemento pubblicato con successo")
                } else if (saveType === "salva") {
                    setTitolo("Salva elemento")
                    setMessaggio("Elemento salvato con successo")
                }

                setMostraModaleOperazione(false)
                setMostraModaleSuccesso(true)
            })
            .catch(() => {
                setMostraModaleOperazione(false)
                setMostraModaleErrore(true)
                if (saveType === "salva-bozza") {
                    setTitolo("Errore nel salvataggio della bozza")
                    setMessaggio("Si è verificato un errore durante il salvataggio della bozza.")
                }
                if (saveType === "pubblica") {
                    setTitolo("Errore nella pubblicazione dell'elemento")
                    setMessaggio("Si è verificato un errore durante la pubblicazione dell'elemento.")
                }
                if (saveType === "salva") {
                    setTitolo("Errore nel salvataggio dell'elemento")
                    setMessaggio("Si è verificato un errore durante il salvataggio dell'elemento.")
                }
            })
            .finally(()=>{
                props.setDirty(true)
            })
    }

    // CHECK
    const checkIniziativa= () => {
        const { iniTitolo, iniDataInizio, iniDataChiusura, iniDescrizione, iniFasi } = form
        let newErrors = {}

        newErrors = checkTitolo(iniTitolo, newErrors)
        newErrors = checkDate(iniDataInizio, iniDataChiusura, newErrors)
        newErrors = checkDescrizione(iniDescrizione, newErrors)
        newErrors = checkFasi(iniFasi, newErrors)

        return newErrors
    }

    const checkTitolo = (iniTitolo, newErrors) => {
        if (!iniTitolo || iniTitolo === '')
            newErrors.iniTitolo = "Inserisci il titolo"

        return newErrors
    }

    const checkDate = (iniDataInizio, iniDataChiusura, newErrors) => {
        let error = false
        const dataInizio = dayjs(iniDataInizio)
        const dataChiusura = dayjs(iniDataChiusura)
        const msgs = {
            dataInizioRequired: "Inserisci la data di inizio",
            dataFineRequired: "Inserisci la data di chiusura",
            invalidDate: "Controllare i valori delle date inserite",
            dataInizioPrimaDiOggi: "La data di inizio non può essere inferiore alla data di oggi",
            dataInizioPrecedenteDataDb: "La data di inizio non può essere inferiore a quella precedentemente salvata",
            dataFinePrimaDataInizio: "La data di chiusura non può essere antecedente alla data di inizio"
        }

        // La data di inizio non può essere vuota
        if (!iniDataInizio || iniDataInizio === '') {
            newErrors.iniDataInizio = msgs.dataInizioRequired
            error = true
        }

        // La data di chiusura non può essere vuota
        if (!iniDataChiusura || iniDataChiusura === '') {
            newErrors.iniDataChiusura = msgs.dataFineRequired
            error = true
        }

        if (!error) {
            // La data di inizio deve essere valida
            if (dayjs(iniDataInizio, 'YYYY-MM-DD', true).isValid() === false) {
                newErrors.iniDataInizio = msgs.invalidDate
                error = true
            }
            // La data di chiusura deve essere valida
            if (dayjs(iniDataChiusura, 'YYYY-MM-DD', true).isValid() === false) {
                newErrors.iniDataChiusura = msgs.invalidDate
                error = true
            }
        }

        // In inserimento del bilancio la data di inizio non può essere inferiore a oggi
        if (!error) {
            if (props.gestisci === "addNew" && !permettiDataInizioPrimaDiOggi) {
                let today = dayjs(new Date(dayjs().year(), dayjs().month(), dayjs().date()))
                if (dayjs(dataInizio).isBefore(today, 'd')) {
                    newErrors.iniDataInizio = msgs.dataInizioPrimaDiOggi
                    error = true
                }
            }
        }

        // In modifica del bilancio la data di inizio non può essere inferiore a quella salvata in db
        if (!error) {
            if (props.gestisci !== "addNew" && !permettiDataInizioIniziativaPrecedenteDataInizioDb) {
                if (dayjs(dataInizio).isBefore(dayjs(dataInizioCurrent), 'd')) {
                    newErrors.iniDataInizio = msgs.dataInizioPrecedenteDataDb
                    error = true
                }
            }
        }

        // La data di chiusura non può essere antecedente alla data di inizio
        if (!error) {
            if (dayjs(dataChiusura).isBefore(dayjs(dataInizio), 'd')) {
                newErrors.iniDataChiusura = msgs.dataFinePrimaDataInizio
                error = true
            }
        }

        return newErrors
    }

    const checkDescrizione = (iniDescrizione, newErrors) => {
        if (!iniDescrizione || iniDescrizione === '')
            newErrors.iniDescrizione = "Inserisci la descrizione"

        return newErrors
    }

    const checkFasi = (iniFasi, newErrors) => {
        let fasePrec
        let errorsFasi = []
        iniFasi.forEach(fase => {
            let errors = {}
            errors = checkFasiTitolo(fase, errors)
            errors = checkFasiDescrizione(fase, errors)
            errors = checkFasiDate(fase, fasePrec, errors)
            errorsFasi.push(errors)
            fasePrec = fase
        })
        newErrors.errorsFasi = errorsFasi

        return newErrors
    }

    const checkFasiTitolo = (fase, newErrors) => {
        if (!fase.finiTitolo || fase.finiTitolo === '')
            newErrors.finiTitolo = "Inserisci il titolo"

        return newErrors
    }

    const checkFasiDescrizione = (fase, newErrors) => {
        if (!fase.finiDescrizione || fase.finiDescrizione === '')
            newErrors.finiDescrizione = "Inserisci la descrizione"

        return newErrors
    }

    const checkFasiDate = (fase, fasePrec, newErrors) => {
        const msgs = {
            dataInizioRequired: "Inserisci la data di inizio",
            dataFineRequired: "Inserisci la data di fine",
            invalidDate: "Controllare i valori delle date inserite",
            dateFaseInPeriodoBilancio: "Le date di inizio e fine devono essere comprese tra le date di inizio e chiusura",
            dataFinePrimaDataInizio: "La data di fine non può essere antecedente alla data di inizio",
            sovrapposizioneFasiNonPermessa: "La data di inizio deve essere successiva alla data di inizio della fase precedente",
            dataInizioPrecedenteDataDb: "La data di inizio non può essere inferiore a quella precedentemente salvata",
        }
        let error = false

        // Le date di inizio e fine non possono essere vuota
        if (!fase.finiDataInizio || fase.finiDataInizio === '') {
            newErrors.finiDataInizio = msgs.dataInizioRequired
            error = true
        }
        if (!fase.finiDataFine || fase.finiDataFine === '') {
            newErrors.finiDataFine = msgs.dataFineRequired
            error = true
        }

        // Le date di inizio e fine devono essere valide
        if (!error) {
            if (dayjs(fase.finiDataInizio, 'YYYY-MM-DD', true).isValid() === false) {
                newErrors.finiDataInizio = msgs.invalidDate
                error = true
            }
            if (dayjs(fase.finiDataFine, 'YYYY-MM-DD', true).isValid() === false) {
                newErrors.finiDataFine = msgs.invalidDate
                error = true
            }
        }

        // Il periodo della fase deve essere compreso all'interno del periodo dell'intero bilancio
        if (!error) {
            if (
                dayjs(fase.finiDataInizio).isBefore(dayjs(form.iniDataInizio), 'd') ||
                dayjs(fase.finiDataInizio).isAfter(dayjs(form.iniDataChiusura), 'd')
            ) {
                newErrors.finiDataInizio = msgs.dateFaseInPeriodoBilancio
                error = true
            }
            if (
                dayjs(fase.finiDataFine).isAfter(dayjs(form.iniDataChiusura), 'd') ||
                dayjs(fase.finiDataFine).isBefore(dayjs(form.iniDataInizio), 'd')
            ) {
                newErrors.finiDataFine = msgs.dateFaseInPeriodoBilancio
                error = true
            }
        }

        // La data di fine non può essere antecedente alla data di inizio
        if (!error) {
            if (dayjs(fase.finiDataFine).isBefore(dayjs(fase.finiDataInizio), 'd')) {
                newErrors.finiDataFine = msgs.dataFinePrimaDataInizio
                error = true
            }
        }

        /*
         * Se il flag permettiSovrapposizioneFasi è false le fasi non possono intersecarsi tra di loro: 
         * la data di inizio di una fase deve essere successiva alla data di fine della fase precedente
        */
        if (!error) {
            if (!permettiSovrapposizioneFasi) {
                if (fasePrec) {
                    if (!(dayjs(fase.finiDataInizio).isAfter(dayjs(fasePrec.finiDataFine), 'd'))) {
                        newErrors.finiDataInizio = msgs.sovrapposizioneFasiNonPermessa
                        error = true
                    }
                }
            }
        }

        // In modifica del bilancio la data di inizio non può essere inferiore a quella salvata in db
        if (!error) {
            if (
                props.gestisci !== "addNew" &&
                !permettiDateInizioFasiPrecedentiDateInizioDb
            ) {
                if (dateInizioFasiCurrent[fase.finiOrdine - 1] !== undefined) {
                    let dataInizioFaseCurrent = dateInizioFasiCurrent[fase.finiOrdine - 1]
                    if (dayjs(fase.finiDataInizio).isBefore(dayjs(dataInizioFaseCurrent), 'd')) {
                        newErrors.finiDataInizio = msgs.dataInizioPrecedenteDataDb
                        error = true
                    }
                }
            }
        }

        return newErrors
    }

    useEffect(() => {
        if (init) {
            if (props.gestisci === "addNew") {
                setIniStato(statiIniziativa.NonPubblicata)
                setIniEdizione(dayjs().year())
            }

            if (props.gestisci === "controlla") {
                setIniId(iniziativa?.iniId)
                setIniStato(iniziativa?.iniStato)
                setIniEdizione(getIniEditionForm())

                setFile(`data:image/png;base64,${iniziativa?.iniBanner}`)
                setUploadedFilesMetadata({ name: iniziativa?.iniBannerNome })

                setForm({
                    iniTitolo: iniziativa?.iniTitolo,
                    iniDataInizio: (iniziativa?.iniDataInizio ? iniziativa?.iniDataInizio : dayjs().format('YYYY-MM-DD')),
                    iniDataChiusura: (iniziativa?.iniDataChiusura ? iniziativa?.iniDataChiusura : dayjs().add(2, 'M').format('YYYY-MM-DD')),
                    iniDescrizione: iniziativa?.iniDescrizione,
                    iniFasi: iniziativa?.fasiIniziativa
                })
            }
            setInit(false)
        }
        // eslint-disable-next-line
    }, [init, props.gestisci, iniziativa])

    return (
        <>
            <Container>
                {props.gestisci === "addNew" && <h2 className="title2">Nuovo elemento</h2>}
                {props.gestisci === "controlla" && <h2 className="title2">Controlla elemento</h2>}

                {["addNew", "controlla"].includes(props.gestisci) &&
                    <Row>
                        <Col className="mb-4 italic required-alert">I campi contrassegnati da un asterisco (*) sono obbligatori</Col>
                    </Row>
                }

                <Form className={validato === true ? "validato" : "non-validato"}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <strong>Titolo: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    value={form.iniTitolo}
                                    onChange={e => setField('iniTitolo', e.target.value)}
                                    className={getValidationStyle("iniTitolo")}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    <strong>{errors.iniTitolo}</strong>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    <strong>Data inizio: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    value={form.iniDataInizio}
                                    onChange={e => setField('iniDataInizio', e.target.value)}
                                    className={getValidationStyle("iniDataInizio")}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    <strong>{errors.iniDataInizio}</strong>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    <strong>Data chiusura: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    value={form.iniDataChiusura}
                                    onChange={e => setField('iniDataChiusura', e.target.value)}
                                    className={getValidationStyle("iniDataChiusura")}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    <strong>{errors.iniDataChiusura}</strong>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-3 datepicker-cm-validation">
                                <Form.Label>
                                    <strong>Edizione: </strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={iniEdizione}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <strong>Descrizione: </strong>
                                    {mandatoryField()}
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    value={form.iniDescrizione}
                                    onChange={e => setField('iniDescrizione', e.target.value)}
                                    className={"no-resize " + getValidationStyle("iniDescrizione")}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    <strong>{errors.iniDescrizione}</strong>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mt-4 mb-3">
                        <Col md={6}>
                            <strong>Carica banner</strong>
                        </Col>
                        <Col md={6} className="text-end">
                            <div>
                                <input
                                    className="d-none"
                                    type="file"
                                    multiple={true}
                                    ref={el => inputFile = el}
                                    onChange={handleDisplayFileDetails}
                                />
                                <Button
                                    onClick={handleUpload}
                                    disabled={uploadedFilesMetadata.name}>
                                    <FiUpload className="me-2" size="1em" /> Carica
                                </Button>
                            </div>
                        </Col>
                        {erroreAllegati &&
                            <Form.Label className="mt-1 text-danger d-block"><strong>
                                {erroreAllegati}
                            </strong>
                            </Form.Label>}
                    </Row>
                    {uploadedFilesMetadata.name && file &&
                        <Card className="text-center mb-4">
                            <Card.Header className="d-flex justify-content-between">
                                <strong className="my-auto">
                                    <BsFileEarmarkRichtext size="1.5em" />
                                    {uploadedFilesMetadata.name}
                                </strong>
                                <Button variant="outline-danger" onClick={() => { deleteFile(uploadedFilesMetadata.name) }}>
                                    <GoTrash size="1.5em" />
                                </Button>
                            </Card.Header>
                            <Figure className="p-1 allegatoSegnalazione">
                                <Figure.Image
                                    width={800}
                                    height={500}
                                    src={file}
                                />
                            </Figure>
                        </Card>
                    }
                    <Row>
                        <Col>
                            <h4 className="title3">Fasi</h4>
                        </Col>
                    </Row>
                    {form.iniFasi
                        .sort((a, b) => { return a.finiOrdine - b.finiOrdine })
                        .map((fase, index) => (
                           <Fase
                                key={index}
                                index={index}
                                fase={fase}
                                setFaseField={setFaseField}
                                mandatoryField={mandatoryField}
                                errors={errors}
                                validato={validato}
                                handleDeleteFase={handleDeleteFase}
                            /> 
                        ))
                    }
                    <Row>
                        <Col>
                            <Button
                                size="sm"
                                onClick={aggiungiFase}
                            >
                                Aggiungi fase  <IoMdAdd style={{ fontSize: "1.3em" }} />
                            </Button>
                        </Col>
                    </Row>

                    <AzioniIniziativa
                        setMostraTabella={props.setMostraTabella}
                        iniStato={iniStato}
                        handleSubmit={handleSubmit}
                    />
                </Form>
            </Container>

            {/* modale di conferma operazione */}
            <ModalConferma
                mostra={mostraModaleOperazione}
                setMostra={setMostraModaleOperazione}
                mode={"continua"}
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma={`Si, ${parolaChiave.toLowerCase()}`}
                btnAnnulla="No, annulla"
                azioneAnnulla={() => { setMostraModaleOperazione(false) }}
                azioneConferma={() => { salvaIniziativa(parolaChiave) }}
            />

            <ModalConferma
                mostra={mostraModaleDeleteFase}
                setMostra={setMostraModaleDeleteFase}
                mode={"continua"}
                titolo="Eliminazione fase"
                messaggio="Confermi l'eliminazione della fase?"
                bodyAlign="text-center"
                btnConferma={`Si, Elimina`}
                btnAnnulla="No, annulla"
                azioneAnnulla={() => { setMostraModaleDeleteFase(false) }}
                azioneConferma={() => { deleteFase(faseToDelete) }}
            />

            {/* modali di esito operazione */}
            <ModalConferma
                mostra={mostraModaleSuccesso}
                setMostra={setMostraModaleSuccesso}
                mode="success"
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma={`Torna a tutti i ${props.comune.impostazioniComune.imcLabelModuloIniziative}`}
                azioneConferma={() => { props.setMostraTabella(true) }}
            />

            {/* modali di errore */}
            <ModalConferma
                mostra={mostraModaleErrore}
                setMostra={setMostraModaleErrore}
                mode="error"
                titolo={titolo}
                messaggio={messaggio}
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={() => {
                    setMostraModaleErrore(false)
                }}
            />
        </>
    )
}