import { Button, Card, Col, Row, Form, Collapse, Container, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import { RiFilter3Line } from "react-icons/ri"
import { getStatusIniziativa } from "../../Utilities/getStatus";
import { statiIniziativa } from "../../Utilities/statiElementEnum";
import { FaRegCalendarAlt } from "react-icons/fa";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import iniziativaServiceInstance from "../../../services/IniziativaService";
import { getStatusOrderIniziativa } from "../../Utilities/getStatusOrder";
import CartaIniziativa from "./CartaIniziativa";
import PaginationBasic from "../Homepage/Pagination"

export default function EsploraIniziative(props) {

    const [dateRange, setDateRange] = useState([null, null]);
    const [checkboxes, setCheckboxes] = useState([false, false])

    const [open, setOpen] = useState(false);

    let [listaIniziative, setListaIniziative] = useState([])
    let [loadingIniziative, setLoadingIniziative] = useState(true)
    const dayjs = require('dayjs');

    // Filtri
    let [statoFilter, setStatoFilter] = useState([])


    /* STATI PER LA PAGINAZIONE */
    const [paginaNumero, setPaginaNumero] = useState(0)
    const elementiPerPagina = 5
    const paginaVisitata = paginaNumero * elementiPerPagina
    /* ************************ */

    const pulisciFiltri = () => {
        setDateRange([null, null])
        setCheckboxes([false, false, false, false])
        setStatoFilter([])
        setPaginaNumero(0); // Resetto alla prima pagina

    }

    const getIniziative = () => {
        
        let ret = listaIniziative
            .map(ini => { return { ...ini, iniStato: getStatusIniziativa(ini, props.comune) } })
            .filter(iniziativa => iniziativa.iniStato !== statiIniziativa.NonPubblicata )

        if (statoFilter.length > 0) {
            ret = ret.filter(ini => statoFilter.includes(getStatusIniziativa(ini)))
        }

        if (dateRange[0]) {
            let startDay = dayjs(dateRange[0])
            ret = ret.filter((ini) => (dayjs(ini.iniDataInizio).isAfter(startDay)) || dayjs(ini.iniDataInizio).isSame(startDay))
        }

        if (dateRange[1]) {
            let endDay = dayjs(dateRange[1])
            ret = ret.filter((ini) => (dayjs(ini.iniDataInizio).isBefore(endDay)) || dayjs(ini.iniDataInizio).isSame(endDay))
        }

        return ret
    }

    const handleDateRangeChange = (update) => {
        if (update === null) {
            setDateRange([null, null])
        } else {
            setDateRange(update);
        }
        setPaginaNumero(0); // Resetto alla prima pagina

    }

    // Filtri
    let filterByStato = (stato, checked) => {
        if (checked === true) {
            if (statoFilter.filter(statoItem => statoItem === stato).length === 0)
                setStatoFilter(old => [...old, stato])
        } else {
            setStatoFilter(statoFilter.filter(statoItem => statoItem !== stato))
        }
        setPaginaNumero(0); // Resetto alla prima pagina

    }

    useEffect(() => {
        if (props.comune.comId) {
            /*iniziativaServiceInstance.getAllByComune(props.comune.comId)
                .then(res => {
                    setListaIniziative(res.data)
                })
                .catch(() => { setListaIniziative([]) })
                .finally(() => { setLoadingIniziative(false) })*/
                iniziativaServiceInstance.getIniziativaWithoutProjects(props.comune.comId)
                .then(res => {
                    setListaIniziative(res.data)
                })
                .catch(() => { setListaIniziative([]) })
                .finally(() => { setLoadingIniziative(false) })
        }
    }, [props.comune.comId])

    const handleCheckbox = (index) => {
        let tmp = checkboxes
        tmp[index] = !tmp[index]
        setCheckboxes(tmp)
    }

    return (
        <>
            <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
            <h1 className="title mt-4 text-center dark">
                {props.comune.impostazioniComune.imcLabelModuloIniziative}
            </h1>
            <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "100vh" }}>
                <Card.Body className="p-0 m-0">
                    {/* FILTRI A SCHERMO PICCOLO */}
                    <Row className="d-md-none w-100 p-0 m-0">
                        <Col xs={12} className="p-1">
                            <Button className="w-100" onClick={() => setOpen(!open)}>
                                <RiFilter3Line className="me-2" />
                                Mostra filtri
                            </Button>
                        </Col>
                        <Collapse in={open} className="border-bottom pb-3">
                            <div id="example-collapse-text">
                                <FilterForm
                                    statiIniziativa={statiIniziativa}
                                    checkboxes={checkboxes}
                                    filterByStato={filterByStato}
                                    handleCheckbox={handleCheckbox}
                                    dateRange={dateRange}
                                    handleDateRangeChange={handleDateRangeChange}
                                    pulisciFiltri={pulisciFiltri}
                                />
                            </div>
                        </Collapse>
                    </Row>

                    {/* FILTRI A SCHERMO GRANDE */}
                    <Row className="w-100 m-0 p-0" style={{ minHeight: "100vh" }}>
                        <Col md={3} className="bg-light d-none d-md-block">
                            <FilterForm
                                statiIniziativa={statiIniziativa}
                                checkboxes={checkboxes}
                                filterByStato={filterByStato}
                                handleCheckbox={handleCheckbox}
                                dateRange={dateRange}
                                handleDateRangeChange={handleDateRangeChange}
                                pulisciFiltri={pulisciFiltri}
                            />
                        </Col>

                        {/* TUTTE LE INIZIATIVE */}
                        <Col md={9} xs>
                            {loadingIniziative ?
                                <Container className="text-center mt-5">
                                    <Spinner animation="border" role="status" />
                                </Container>
                                :
                                <>
                                    {listaIniziative.length > 0 &&
                                        getIniziative()
                                            .map(ini => { return { ...ini, iniStato: getStatusIniziativa(ini, props.comune) } })
                                            .filter(iniziativa => iniziativa.iniStato !== statiIniziativa.NonPubblicata)
                                            .sort((a, b) => { return getStatusOrderIniziativa(b.iniEdizione) - getStatusOrderIniziativa(a.iniEdizione) })
                                            .slice(paginaNumero * elementiPerPagina, (paginaNumero +1) * elementiPerPagina)
                                            .map((iniziativa, index) => (
                                                <CartaIniziativa key={index} iniziativa={iniziativa} setActiveTab={props.setActiveTab} />
                                            ))}
                                    <PaginationBasic
                                        activepage={paginaNumero}
                                        setActivePage={setPaginaNumero}
                                        numItems={getIniziative().length}
                                        itemsPerPage={elementiPerPagina}
                                    />

                                </>
                            }
                        </Col>
                    </Row>
                </Card.Body>

            </Card>
        </>
    )
}


const FilterForm = ({ statiIniziativa, checkboxes, filterByStato, handleCheckbox, dateRange, handleDateRangeChange, pulisciFiltri }) => (
    <>
        <h4 className="mt-4">Filtra per:</h4>
        <div className="divider my-4" />
        <p>Stato</p>
        <Form.Group>
            {Object.values(statiIniziativa)
                .filter(e => e !== statiIniziativa.NonPubblicata)
                .map((stato, index) => (
                    <Form.Check
                        key={index}
                        type="checkbox"
                        label={stato}
                        name={stato}
                        checked={checkboxes[index]}
                        onChange={(e) => {
                            filterByStato(e.target.name, e.target.checked);
                            handleCheckbox(index);
                        }}
                    />
                ))}
        </Form.Group>
        <div className="divider my-4" />
        <p>Periodo edizione &nbsp;
            <span className="small text-muted">(Da ... a)</span>
        </p>
        <div>
            <DateRangePicker className="w-100"
                id="datePickerPetizioni"
                onChange={handleDateRangeChange}
                value={dateRange}
                returnValue="range"
                calendarIcon={<FaRegCalendarAlt style={{ height: '1em', width: '1em' }} />}
                yearPlaceholder='aaaa'
                format='y'
                maxDetail="decade"
                calendarProps={{ minDetail: "decade" }}
            />
        </div>
        <div className="d-flex justify-content-center mt-4">
            <Button size="sm" variant="outline-primary" onClick={pulisciFiltri}>
                Pulisci filtri di ricerca
            </Button>
        </div>
    </>
);


