import React from "react"
import { useState, useEffect } from 'react';
import { Form, Table, Row, Col, Container, OverlayTrigger, Tooltip, Button } from "react-bootstrap"
import { IoMdDownload } from "react-icons/io"
import { MdDelete } from "react-icons/md"
import { FaUndo } from "react-icons/fa"
var fileDownload = require('js-file-download');

export default function AllegatiProgetti(props) {

    const [rowsToMarkToDelete, setRowsToMarkToDelete] = useState([])

    const ordinaAllegati = (a, b) => {
        return a.allProgNome.localeCompare(b.allProgNome);
    }

    async function downloadAllegato(arrayBuffer, name) {
        const base64Response = await fetch(`data:file;base64,${arrayBuffer}`);
        const blob = await base64Response.blob();
        fileDownload(blob, name);
    }

    const markAllegatoToDelete = (allegato, action) => {
        if (action === 'delete') {
            if (props.allegatiToDelete.filter(all => (all.allProgId === allegato.allProgId)).length === 0) {
                console.log("allegatiToDelete marked: ", [...props.allegatiToDelete, allegato] )
                props.setAllegatiToDelete([...props.allegatiToDelete, allegato])
                setRowsToMarkToDelete(rowsToMarkToDelete.map(el => { if (el.id === allegato.allProgId) return { id: allegato.allProgId, toDelete: true }; return el }))
            }
        }

        if (action === 'resume') {
            if (props.allegatiToDelete.filter(all => (all.allProgId === allegato.allProgId)).length === 1) {
                props.setAllegatiToDelete(props.allegatiToDelete.filter(el => el.allProgId !== allegato.allProgId))
                setRowsToMarkToDelete(rowsToMarkToDelete.map(el => { if (el.id === allegato.allProgId) return { id: allegato.allProgId, toDelete: false }; return el }))
            }
        }
    }

    useEffect(() => {
        if (props.allegatiProgetto?.length > 0)
            setRowsToMarkToDelete(props.allegatiProgetto.map(el => { return { id: el.allProgId, toDelete: false } }))
    }, [props.allegatiProgetto])

    const getIsToDelete = (id) => {
        if (
            rowsToMarkToDelete.filter(el => el.id === id).length > 0 &&
            rowsToMarkToDelete.filter(el => el.id === id)[0].toDelete === true
        ) {
            return true
        }

        return false
    }

    return (
        <>
            {
                props.allegatiProgetto?.length > 0 &&
                <Row>
                    <Col>
                        <Form.Group className="mt-3 mb-1">
                            <Form.Label>
                                <strong>Allegati </strong>
                            </Form.Label>
                        </Form.Group>
                        <Table striped bordered hover responsive className="allegatiProposta">
                            <thead>
                                <tr>
                                    <th style={{ width: "65%" }}>Titolo</th>
                                    <th className="text-center" style={{ width: "20%" }}>Dimensione</th>
                                    <th className="text-center" style={{ width: "15%" }}>Azioni</th>
                                </tr>
                            </thead>
                            <tbody style={{ verticalAlign: "middle" }}>
                                {
                                    props.allegatiProgetto
                                        .sort(ordinaAllegati)
                                        .map(allegato => (
                                            <tr
                                                key={allegato.allProgId}
                                                className={getIsToDelete(allegato.allProgId) === true ? "toDelete" : ""}
                                            >
                                                <td>{allegato.allProgNome}</td>
                                                <td className="text-center">
                                                    {allegato.allProgSize &&
                                                    Math.ceil(allegato.allProgSize / 1024)  + "kb"}
                                                </td>
                                                <td className="text-center">
                                                    <Container className="d-flex justify-content-between py-0 px-3 m-0">
                                                        <OverlayTrigger placement="bottom" overlay={<Tooltip>Scarica</Tooltip>}>
                                                            <Button variant="noBackground"
                                                                onClick={() => { downloadAllegato(allegato.allProgFile, allegato.allProgNome) }}
                                                                className="download"
                                                            >
                                                                <IoMdDownload style={{ fontSize: "1.5em" }} />
                                                            </Button>
                                                        </OverlayTrigger>
                                                        {getIsToDelete(allegato.allProgId) === false ?
                                                            <OverlayTrigger placement="bottom" overlay={ <Tooltip>Elimina</Tooltip> }>
                                                                <Button variant="noBackground" disabled={props.readOnly} onClick={() => { markAllegatoToDelete(allegato, 'delete') }}>
                                                                    <MdDelete style={{ fontSize: "1.5em" }} />
                                                                </Button>
                                                            </OverlayTrigger>
                                                            :
                                                            <OverlayTrigger placement="bottom" overlay={ <Tooltip>Ripristina</Tooltip>}>
                                                                <Button variant="noBackground" 
                                                                    onClick={() => { markAllegatoToDelete(allegato, 'resume') }}
                                                                    className="resume"
                                                                >
                                                                    <FaUndo style={{ fontSize: "1.1em" }} />
                                                                </Button>
                                                            </OverlayTrigger>
                                                        }
                                                    </Container >
                                                </td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            }
        </>
    )
}