import { Button, Card, Col, Row, Form, Collapse, Container, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import BilancioPartecipativoService from "../../../services/BilancioPartecipativoService";
import { RiFilter3Line } from "react-icons/ri"
import CartaBilancio from "./CartaBilancio";
import { getStatusBilancio } from "../../Utilities/getStatus";
import { getStatusOrderBilancio } from "../../Utilities/getStatusOrder";
import { statiBilancio } from "../../Utilities/statiElementEnum";
import { FaRegCalendarAlt } from "react-icons/fa";
import PaginationBasic from "../Homepage/Pagination"


import DateRangePicker from '@wojtekmaj/react-daterange-picker';
export default function EsploraBilanci(props) {

    const [dateRange, setDateRange] = useState([null, null]);
    const [checkboxes, setCheckboxes] = useState([false, false])

    const [open, setOpen] = useState(false);

    const [paginaNumero, setPaginaNumero] = useState(0)
    const elementiPerPagina = 5

    let [listaBilanci, setListaBilanci] = useState([])
    let [loadingBil, setLoadingBil] = useState(true)
    const dayjs = require('dayjs');

    // Filtri
    let [statoFilter, setStatoFilter] = useState([])

    const pulisciFiltri = () => {
        setDateRange([null, null])
        setCheckboxes([false, false, false, false])
        setStatoFilter([])
        setPaginaNumero(0); // Resetto alla prima pagina

    }

    const getBilanci = () => {
        let ret = listaBilanci
        if (statoFilter.length > 0) {
            ret = ret.filter(bil => statoFilter.includes(getStatusBilancio(bil)))
        }

        if (dateRange[0]) {
            let startDay = dayjs(dateRange[0])
            ret = ret.filter((bil) => (dayjs(bil.bilDataInizio).isAfter(startDay)) || dayjs(bil.bilDataInizio).isSame(startDay))
        }

        if (dateRange[1]) {
            let endDay = dayjs(dateRange[1])
            ret = ret.filter((bil) => (dayjs(bil.bilDataInizio).isBefore(endDay)) || dayjs(bil.bilDataInizio).isSame(endDay))
        }

        return ret
    }

    const handleDateRangeChange = (update) => {
        if (update === null) {
            setDateRange([null, null])
        } else {
            setDateRange(update);
        }

        setPaginaNumero(0); // Resetto alla prima pagina

    }

    // Filtri
    let filterByStato = (stato, checked) => {
        if (checked === true) {
            if (statoFilter.filter(statoItem => statoItem === stato).length === 0)
                setStatoFilter(old => [...old, stato])
        } else {
            setStatoFilter(statoFilter.filter(statoItem => statoItem !== stato))
        }

        setPaginaNumero(0); // Resetto alla prima pagina

    }

    useEffect(() => {
        if (props.comune.comId) {
            BilancioPartecipativoService.getAllByComune(props.comune.comId)
                .then(res => {
                    setListaBilanci(res.data)
                })
                .catch(() => { setListaBilanci([]) })
                .finally(() => { setLoadingBil(false) })
        }
    }, [props.comune.comId])

    const handleCheckbox = (index) => {
        let tmp = checkboxes
        tmp[index] = !tmp[index]
        setCheckboxes(tmp)
    }

    return (
        <>
            <div className="bg-secondary w-100 position-absolute" style={{ minHeight: "30vh", zIndex: "-1" }} />
            <h1 className="title mt-4 text-center dark">{props.comune.impostazioniComune.imcLabelModuloBilanci}</h1>
            <Card className="mx-md-5 mt-2 mb-5" style={{ minHeight: "100vh" }}>
                <Card.Body className="p-0 m-0">
                    {/* FILTRI A SCHERMO PICCOLO */}
                    <Row className="d-md-none w-100 p-0 m-0">
                        <Col xs={12} className="p-1">
                            <Button className="w-100" onClick={() => setOpen(!open)} >
                                <RiFilter3Line className="me-2" />
                                Mostra filtri
                            </Button>
                        </Col>
                        <Collapse in={open} className="border-bottom pb-3">
                            <div id="example-collapse-text">
                                <h4 className="mt-4">Filtra per:</h4>
                                <div className="divider my-4" />
                                <Row>
                                    <Col>
                                        <p >Periodo edizione &nbsp;
                                            <span className="small text-muted ">(Da ... a)</span>
                                        </p>
                                        <div>
                                            <DateRangePicker className="w-100"
                                                id="datePickerPetizioni"
                                                onChange={(update) => { handleDateRangeChange(update) }}
                                                value={dateRange}
                                                returnValue="range"
                                                calendarIcon={<FaRegCalendarAlt style={{ height: '1em', width: '1em' }} />}
                                                dayPlaceholder="gg"
                                                monthPlaceholder='mm'
                                                yearPlaceholder='aaaa'
                                                yearAriaLabel="year"
                                                maxDetail="year"
                                                format='dd/MM/y'
                                            />
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p >Stato</p>
                                        <Form.Group>
                                            {Object.values(statiBilancio)
                                                .filter(e => e !== statiBilancio.NonPubblicato)
                                                .map((stato, index) => (
                                                    <Form.Check
                                                        key={index}
                                                        type="checkbox"
                                                        label={stato}
                                                        name={stato}
                                                        checked={checkboxes[index]}
                                                        onChange={(e) => {
                                                            filterByStato(e.target.name, e.target.checked)
                                                            handleCheckbox(index)
                                                        }}
                                                    />
                                                )
                                                )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-center mt-4">
                                    <Button size="sm" variant="outline-primary"
                                        onClick={() => { pulisciFiltri() }}>
                                        Pulisci filtri di ricerca
                                    </Button>
                                </div>

                            </div>
                        </Collapse>
                    </Row>

                    <Row className="w-100 m-0 p-0" style={{ minHeight: "100vh" }}>
                        {/* FILTRI A SCHERMO GRANDE*/}
                        <Col md={3} className="bg-light d-none d-md-block">
                            <h4 className="mt-4">Filtra per:</h4>
                            <div className="divider my-4" />
                            <p >Stato</p>
                            <Form.Group>
                                {Object.values(statiBilancio)
                                    .filter(e => e !== statiBilancio.NonPubblicato)
                                    .map((stato, index) => (
                                        <Form.Check
                                            key={index}
                                            type="checkbox"
                                            label={stato}
                                            name={stato}
                                            checked={checkboxes[index]}
                                            onChange={(e) => {
                                                filterByStato(e.target.name, e.target.checked)
                                                handleCheckbox(index)
                                            }}
                                        />
                                    )
                                    )}
                            </Form.Group>

                            <div className="divider my-4" />

                            <p >Periodo edizione &nbsp;
                                <span className="small text-muted ">(Da ... a)</span>
                            </p>
                            <div>
                                <DateRangePicker className="w-100"
                                    id="datePickerPetizioni"
                                    onChange={(update) => { handleDateRangeChange(update) }}
                                    value={dateRange}
                                    returnValue="range"
                                    calendarIcon={<FaRegCalendarAlt style={{ height: '1em', width: '1em' }} />}
                                    yearPlaceholder='aaaa'
                                    format='y'
                                    maxDetail="decade"
                                    calendarProps={{ minDetail: "decade" }}
                                />
                            </div>

                            <div className="divider my-4" />
                            <div className="d-flex justify-content-center">
                                <Button size="sm" variant="outline-primary"
                                    onClick={() => { pulisciFiltri() }}>
                                    Pulisci filtri di ricerca
                                </Button>
                            </div>

                        </Col>

                        {/* TUTTI I BILANCI */}
                        <Col md={9} xs>
                            {loadingBil ?
                                <Container className="text-center mt-5">
                                    <Spinner animation="border" role="status" />
                                </Container>
                                :
                                <>
                                    {listaBilanci.length > 0 &&
                                        getBilanci()
                                            .map(bil => { return { ...bil, bilStato: getStatusBilancio(bil, props.comune) } })
                                            .filter(bilancio => bilancio.bilStato !== statiBilancio.NonPubblicato)
                                            .sort((a, b) => { return getStatusOrderBilancio(b.bilEdizione) - getStatusOrderBilancio(a.bilEdizione) })
                                            .slice(paginaNumero * elementiPerPagina, (paginaNumero +1) * elementiPerPagina)
                                            .map((bilancio, index) => (
                                                <CartaBilancio key={index} bilancio={bilancio} setActiveTab={props.setActiveTab} />
                                            ))}
                                    <PaginationBasic
                                        activepage={paginaNumero}
                                        setActivePage={setPaginaNumero}
                                        numItems={getBilanci().length}
                                        itemsPerPage={elementiPerPagina}
                                    />
                                </>
                            }
                        </Col>
                    </Row>
                </Card.Body>

            </Card>
        </>
    )
}
