import { Card, Row, Col, Button, Badge } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import progettoServiceInstance from "../../../services/ProgettoService";
import { FiChevronRight as Scopri } from 'react-icons/fi'
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getIniziativaColor } from "../../Utilities/getBorderColor";
import NumberFormat from "react-number-format"
import { statiProgetto } from "../../Utilities/statiElementEnum";

export default function CartaIniziativa(props) {

    const navigate = useNavigate()

    // Stato per il conteggio dei progetti
    const [progettiCount, setProgettiCount] = useState(0);

    useEffect(() => {
        if (props.iniziativa.iniId) {
            progettoServiceInstance.getCountProgettiValidByIniziativaId(props.iniziativa.iniId)
                .then(response => {
                    setProgettiCount(response.data); // Imposta il conteggio dei progetti
                })
                .catch(error => {
                    console.error("Errore nel recupero del conteggio dei progetti:", error);
                    setProgettiCount(0); // Imposta a 0 in caso di errore
                });
        }
    }, [props.iniziativa.iniId]); // Esegui l'effetto quando cambia l'ID dell'iniziativa

    return (
        <Card className="cartaSommario w-100 mt-4 mb-2"
            border={getIniziativaColor(props.iniziativa)}
        >
            <Card.Body>
                <h5><strong>{props.iniziativa.iniTitolo}</strong></h5>
                <Row className="w-100 m-0 mt-3 p-0">
                    <Col xs={6} md={3}>
                        <strong>Edizione</strong>
                        <p>{props.iniziativa.iniEdizione}</p>
                    </Col>
                    <Col xs={6} md={3}>
                        <strong>Data inizio:</strong>
                        <p>{dayjs(props.iniziativa.iniDataInizio).format("DD/MM/YYYY")}</p>
                    </Col>

                    <Col xs={6} md={3}>
                        <strong>Data chiusura:</strong>
                        <p>{dayjs(props.iniziativa.iniDataChiusura).format("DD/MM/YYYY")}</p>
                    </Col>

                    <Col xs={6} md={3} className="d-flex align-self-center">
                        <Badge pill
                            bg={getIniziativaColor(props.iniziativa)}
                            className="mt-auto mx-md-auto py-2 "
                            style={{ fontWeight: '100', fontSize: '0.80em' }}>
                            {props.iniziativa.iniStato}
                        </Badge>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className='text-center px-0 pb-3'>
                <Row className='w-100 p-0 m-0 d-flex'>
                    <Col xs={12} md={9} className='pr-0'>
                        <span >
                            <strong className="bigText">
                                <NumberFormat
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={progettiCount} // Usa il valore dallo stato
                                    //value={props.iniziativa.progetti && props.iniziativa.progetti.filter(p => ![statiProgetto.InValidazione, statiProgetto.Rifiutato].includes(p.progStato)).length}
                                />
                            </strong>
                            {' '} progetti
                        </span>

                    </Col>

                    <Col xs={12} md={3} className="p-0 d-flex align-items-baseline">
                        <Button variant="outline-primary" size="sm" className="mt-auto mx-auto"
                            onClick={() => {
                                props.setActiveTab("informazioni")
                                navigate('/iniziativa', { state: { iniId: props.iniziativa.iniId } })
                            }}>
                            Scopri
                            < Scopri />
                        </Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    )
}